import axios from 'axios';

// const HOST_URL = "http://127.0.0.1:8000/api";

const HOST_URL = "http://apitramite.munidepocollay.gob.pe/api";

const instance = axios.create({
    baseURL: HOST_URL,
    headers: { 'Accept': 'application/json' }
});


instance.interceptors.response.use(function (response) {
    return response;
}, function (error) {

    return Promise.reject(error);
});

export default instance;

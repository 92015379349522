import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import ElementUI from 'element-ui';
import VueSweetalert2 from 'vue-sweetalert2';

import 'element-ui/lib/theme-chalk/index.css';
import 'sweetalert2/dist/sweetalert2.min.css';

import { VueReCaptcha } from 'vue-recaptcha-v3'

// For more options see below
Vue.use(VueReCaptcha, { siteKey: '6LeHXuAZAAAAAOkiOBinVrajsyigD4XA7pNxDNRV' })
Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueSweetalert2);

new Vue({
  el: '#app',
  router,
  vuetify,
  render: h => h(App)
})
